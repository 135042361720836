import logo from './logo.svg';
import './App.css';
import SimpleImageSlider from "react-simple-image-slider";
import ImageGallery from 'react-image-gallery';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import axios from 'axios'
import { baseurl } from './baseUrl';
import ReactFormInputValidation from "react-form-input-validation";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const images = [
  {
    original: 'assets/img/about/PropertyOwnerBlurred.png',
    // thumbnail: 'assets/img/about/PropertyOwnerBlurred.png',
  },
  {
    original: 'assets/img/about/propertyOwnerListBlue.png',
    // thumbnail: 'assets/img/about/propertyOwnerListBlue.png',
  },
  {
    original: 'assets/img/about/propertyOwnerListBlurred.png',
    // thumbnail: 'assets/img/about/propertyOwnerListBlurred.png',
  },
  {
    original: 'assets/img/about/RentReminder.png',
    // thumbnail: 'assets/img/about/RentReminder.png',
  },
  {
    original: 'assets/img/about/PropertyOwnerBlurred.png',
    // thumbnail: 'assets/img/about/PropertyOwnerBlurred.png',
  },
];


function App() {
  const notify = () => toast("Registered succefully!");
  const [open, setOpen] = React.useState(false);

  const [companyName, setCompanyName] = useState()
  const [companyPhone, setCompanyPhone] = useState()
  const [companyEmail, setCompanyEmail] = useState()
  const [companyLocation, setCompanyLocation] = useState()

  const [inputState, setInput] = useState({});
  const [errors, setErrors] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const data = {
    name: companyName,
    email: companyEmail,
    phone: companyPhone,
    phone: companyPhone,
    branch_name: companyLocation
  }

  function validate() {
    // alert("validate called")
    let input = inputState;
    let errors = {};
    let isValid = true;
    if (!input["name"]) {
      isValid = false;
      errors["name"] = "Please enter Company name.";
    }
    if (!input["branch"]) {
      isValid = false;
      errors["branch"] = "Please enter Location name.";
    }
    if (!input["email"]) {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
    }
    if (!input["phone"]) {
      isValid = false;
      errors["phone"] = "Please enter your Phone Number .";
    }
    if (typeof input["phone"] !== "undefined") {
      var pattern = new RegExp(/^\d{12,12}$/);
      if (!pattern.test(input["phone"])) {
        isValid = false;
        errors["phone"] =
          "Please use the correct format, add 971 before your number and complete 10 digits";
      }
    }

    if (typeof input["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }
    // this.setState({
    //   errors: errors,
    // });
    setErrors(errors)
    return isValid;
  }


  console.log("form datas", data)

  useEffect(() => {


  }, [])

  const phoneOnChnage = (e) => {
    let input = inputState;
    input[e.target.name] = e.target.value;
    // this.setState({ email: e.target.value, input });
    setCompanyPhone(e.target.value, input)
  }
  const emailOnChnage = (e) => {
    let input = inputState;
    input[e.target.name] = e.target.value;
    // this.setState({ email: e.target.value, input });
    setCompanyEmail(e.target.value, input)
  }
  const companyOnChnage = (e) => {
    let input = inputState;
    input[e.target.name] = e.target.value;
    // this.setState({ email: e.target.value, input });
    setCompanyName(e.target.value, input)
  }
  const branchOnChnage = (e) => {
    let input = inputState;
    input[e.target.name] = e.target.value;
    // this.setState({ email: e.target.value, input });
    setCompanyLocation(e.target.value, input)
  }

  const handleForm = async () => {
    console.log("Form Errors", errors)
    if (validate()) {
      axios.post(baseurl, data, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',

        }
      }).then(res => {
        // console.log("registered succefully")
        setOpen(false);
        notify();

      }).catch(e => console.log("Registration Error", e)
      )
    }
  }

  return (
    <div className="App">
      <div>
        <div className="preloader">
          <div className="loader">
            <div className="spinner">
              <div className="spinner-container">
                <div className="spinner-rotator">
                  <div className="spinner-left">
                    <div className="spinner-circle" />
                  </div>
                  <div className="spinner-right">
                    <div className="spinner-circle" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* preloader end */}
        {/* ======== header start ======== */}
        <header className="header">
          <div className="navbar-area">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <nav className="navbar navbar-expand-lg">
                    <a className="navbar-brand" href="index.html">
                      <img className="navbar-brand-img" src="assets/img/logo/SOZOLOGO.png" alt="Logo" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="toggler-icon" />
                      <span className="toggler-icon" />
                      <span className="toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                      <ul id="nav" className="navbar-nav ms-auto">
                        <li className="nav-item">
                          <a className="page-scroll active" href="#home">Home</a>
                        </li>
                        <li className="nav-item">
                          <a className="page-scroll" href="#features">Solutions</a>
                        </li>
                        <li className="nav-item">
                          <a className="page-scroll" href="#about">About</a>
                        </li>
                        <li className="nav-item">
                          <a className="page-scroll" href="#why">Why</a>
                        </li>
                        {/* <li className="nav-item">
                          <a href="javascript:void(0)">Pricing</a>
                        </li> */}
                        {/* <li className="nav-item">
                          <a href="javascript:void(0)">Clients</a>
                        </li> */}
                      </ul>
                    </div>
                    {/* navbar collapse */}
                  </nav>
                  {/* navbar */}
                </div>
              </div>
              {/* row */}
            </div>
            {/* container */}
          </div>
          {/* navbar area */}
        </header>
        {/* ======== header end ======== */}
        {/* ======== hero-section start ======== */}
        <section id="home" className="hero-section">
          <div className="container">
            <div className="row align-items-center position-relative">
              <div className="col-lg-4">
                <div className="hero-content">
                  <h1 className="wow fadeInUp" data-wow-delay=".4s">
                    SOZO Rent
                  </h1>
                  <p className="wow fadeInUp" data-wow-delay=".6s">
                    Grow your business on the go with SOZO Rent, an end-to-end digital solution for property management companies.
                  </p>
                  {/* <button  data-toggle="modal" data-target="#exampleModal" className="main-btn border-btn btn-hover wow fadeInUp" data-wow-delay=".6s">Request Account</button> */}
                  {/* <button onClick={notify}>Notify!</button> */}
                  <div>
                    {/* Button trigger modal */}
                    {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                      Launch demo modal
                    </button> */}
                    {/* Modal */}
                    {/* <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            ...
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div>
                    <Button color="secondary" variant="contained" onClick={handleClickOpen}>
                      Free Demo
                    </Button>
                    <Dialog open={open} onClose={handleClose}>
                      <DialogTitle>Request Form</DialogTitle>
                      <DialogContent>
                        <div className='form-group col-12 has-float-label has-feedback has-feedback-left input-request'>
                          <input onChange={emailOnChnage} type="email" name='email' class="form-control form-control-lg" placeholder="Email*" required />
                          <div className="text-danger">
                            {errors.email}
                          </div>
                        </div>
                        <div className='form-group col-12 has-float-label has-feedback has-feedback-left input-request'>
                          <input onChange={companyOnChnage} type="text" name='name' class="form-control form-control-lg" placeholder="Company Name" required />
                          {/* <label for="email">Company Name</label> */}
                          <div className="text-danger">
                            {errors.name}
                          </div>
                        </div>
                        <div className='form-group col-12 has-float-label has-feedback has-feedback-left input-request'>
                          <input onChange={branchOnChnage} type="text" name='branch' class="form-control form-control-lg" placeholder="Location" required />
                          {/* <label for="email">Company Name</label> */}
                          <div className="text-danger">
                            {errors.branch}
                          </div>
                        </div>
                        {/* <div className='form-group col-12 has-float-label has-feedback has-feedback-left input-request'>
                              <input onChange={emailOnChnage} type="email" name='email' class="form-control form-control-lg"placeholder="Email*" required/>
                              <div className='form-control-feedback'></div>
                           </div> */}
                        <div className='form-group col-12 has-float-label has-feedback has-feedback-left input-request'>
                          <input onChange={phoneOnChnage} type="number" name='phone' placeholder="971XXXXXXXXXX" class="form-control form-control-lg" required />
                          <div className="text-danger">
                            {errors.phone}
                          </div>
                          <div className='form-control-feedback'></div>
                        </div>
                        <div className='form-group col-12 my-2 mt-sm-4'>
                          <button type="button" onClick={handleForm} class="btn btn-primary login-smoobu-button btn-block py-2">Register Now</button>
                        </div>

                      </DialogContent>

                    </Dialog>
                    <ToastContainer style={{zIndex: 10000}} position="bottom-center"/>

                  </div>

                  {/* <Popup style= {{left: 0, width: '100%!important'}} className='row' trigger={<button class="btn btn-primary login-smoobu-button btn-block py-2"> Free Demo</button>} position="right center">
                  <div className='card-body request-form'>
                       <div className='form-row'>
                         <h3>Request Account</h3>
                           <div className='form-group col-12 has-float-label has-feedback has-feedback-left input-request'>
                              <input  onChange={emailOnChnage} type="email" name='email' class="form-control form-control-lg"placeholder="Email*" required/>
                              <div className="text-danger">
                                {errors.email}
                              </div>
                           </div>                           <div className='form-group col-12 has-float-label has-feedback has-feedback-left input-request'>
                              <input onChange={companyOnChnage} type="text" name='name' class="form-control form-control-lg" placeholder="Company Name" required/>
                              <div className="text-danger">
                                {errors.name}
                              </div>
                           </div>

                           <div className='form-group col-12 has-float-label has-feedback has-feedback-left input-request'>
                              <input onChange={phoneOnChnage} type="number" name='phone' placeholder="971XXXXXXXXXX" class="form-control form-control-lg" required/>
                              <div className="text-danger">
                                {errors.phone}
                              </div>
                              <div className='form-control-feedback'></div>
                           </div>
                           <div className='form-group col-12 my-2 mt-sm-4'>
                               <button type="button" onClick={handleForm} class="btn btn-primary login-smoobu-button btn-block py-2">Register Now</button>
                           </div>
                       </div>
                     
                 </div>  
                  </Popup> */}

                  <a href="#features" className="scroll-bottom">
                    <i className="lni lni-arrow-down" /></a>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="hero-img wow fadeInUp" data-wow-delay=".5s">
                  <img className="hero-image" src="assets/img/hero/sozodashboard.PNG" alt />
                </div>
              </div>
            </div>
          </div>
          <div>
            {/* Trigger the modal with a button */}
            {/* <button type="button" className="btn btn-info btn-lg" data-toggle="modal" data-target="#companyReg">Open Modal</button> */}
            {/* Modal */}
            <div className="modal fade" style={{ zIndex: 9999999, background: 'white' }} id="companyReg" tabIndex={-1} role="dialog" aria-labelledby="registermodal" aria-hidden="true">
              <div className="modal-dialog">
                {/* Modal content*/}
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">×</button>
                    <h4 className="modal-title">Modal Header</h4>
                  </div>
                  <div className="modal-body">
                    <p>Some text in the modal.</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======== hero-section end ======== */}
        {/* ======== feature-section start ======== */}
        <section id="features" className="feature-section pt-10">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-8 col-sm-10">
                <div className="single-feature">
                  <div className="icon">
                    {/* <i className="lni lni-bootstrap" /> */}
                    <img src='assets/img/hero/sozorent.png' />
                  </div>
                  <div className="content">
                    <h3>SOZO Rent</h3>
                    <p>
                      Automate your Rent management processes & grow your
                      business with SOZO Rent.

                    </p>
                  </div>
                </div>
              </div>
              {/* <SimpleImageSlider
                width={896}
                height={504}
                images={images}
                showBullets={true}
                showNavs={true}
              /> */}
              {/* <ImageGallery thumbnailPosition='left' items={images} /> */}

              {/* <div style={{ maxWidth: '1100px', margin: '0 auto' }} className="">
               </div> */}

              {/* <div className="col-lg-4 col-md-8 col-sm-10">
                <div className="single-feature">
                  <div className="icon">
                    <i className="lni lni-layout" />
                  </div>
                  <div className="content">
                    <h3>SOZO HRM</h3>
                    <p>
                       Improve your company's HR capabilities with one of the top-rated HRM 
                       and human resource ERP solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-8 col-sm-10">
                <div className="single-feature">
                  <div className="icon">
                    <i className="lni lni-coffee-cup" />
                  </div>
                  <div className="content">
                    <h3>SOZO Accounting</h3>
                    <p>
                     Manage your income and Expenses suitable for business owners to process accounting 
                     transactions.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        {/* ======== feature-section end ======== */}
        {/* ======== about-section start ======== */}
        <section id="about" className="about-section pt-10">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6">
                <div className="about-img">
                  <img src="assets/img/about/Dark-Dashboard.png" alt className="w-100" />
                  <img src="assets/img/about/about-left-shape.svg" alt className="shape shape-1" />
                  <img src="assets/img/about/left-dots.svg" alt className="shape shape-2" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="about-content">
                  <div className="section-title mb-30">
                    <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                      Perfect Solution for property Owners and Property management companeis
                    </h2>
                    <p className="wow fadeInUp" data-wow-delay=".4s">
                      Versitile dashboard
                    </p>
                  </div>
                  {/* <a href="javascript:void(0)" className="main-btn btn-hover border-btn wow fadeInUp" data-wow-delay=".6s">Discover More</a> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======== about-section end ======== */}
        {/* ======== about2-section start ======== */}
        <section id="about" className="about-section pt-15">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6">
                <div className="about-content">
                  <div className="section-title mb-30">
                    <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                      Easy contract tracking Mechanism
                    </h2>
                    <p className="wow fadeInUp" data-wow-delay=".4s">
                      Tenant Pay Cheque | Cash then the propety management companies upload receivables, deposit report will be generated. confirmation will be sent for tenat and owners.
                    </p>
                  </div>
                  <ul>
                    <li>Full Automated System with out any paper work</li>
                    <li>Easy contract tracking, Cheque Tracking, Cheque upload</li>
                    <li>Reminders and Notification Email and SMS</li>
                  </ul>
                  {/* <a href="javascript:void(0)" className="main-btn btn-hover border-btn wow fadeInUp" data-wow-delay=".6s">Learn More</a> */}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 order-first order-lg-last">
                <div className="about-img-2">
                  <img src="assets/img/about/RentReminder.PNG" alt className="w-100" />
                  <img src="assets/img/about/about-right-shape.svg" alt className="shape shape-1" />
                  <img src="assets/img/about/right-dots.svg" alt className="shape shape-2" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======== about2-section end ======== */}
        {/* ======== feature-section start ======== */}
        <section id="why" className="feature-extended-section pt-100">
          <div className="feature-extended-wrapper">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-9">
                  <div className="section-title text-center mb-60">
                    <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                      Why Our Rent Management
                    </h2>
                    <p className="wow fadeInUp" data-wow-delay=".4s">
                      SOZO HRM is designed to help property management companies
                      to streamline the business tasks associated with managing rental properties.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="single-feature-extended">
                    <div className="icon">
                      <i className="lni lni-money-location" />
                    </div>
                    <div className="content">
                      <h3>Easy Cheque Tracking</h3>
                      <p>
                        Easy cheque Tracking, contract Tracking, Invoice management Mechanishm
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="single-feature-extended">
                    <div className="icon">
                      <i className="lni lni-leaf" />
                    </div>
                    <div className="content">
                      <h3>Invoice Tracking</h3>
                      <p>
                        Track Income, Expense and maintenance cost related to the property
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="single-feature-extended">
                    <div className="icon">
                      <i className="lni lni-grid-alt" />
                    </div>
                    <div className="content">
                      <h3>Payment Tracking</h3>
                      <p>
                        Owner and Tenant Payment Tracking, Booking properties
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="single-feature-extended">
                    <div className="icon">
                      <i className="lni lni-javascript" />
                    </div>
                    <div className="content">
                      <h3>Reduce Loss</h3>
                      <p>
                        Reduce administrative labor & manual errors
                        Reduce losses due to inefficient management of receivables
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="single-feature-extended">
                    <div className="icon">
                      <i className="lni lni-layers" />
                    </div>
                    <div className="content">
                      <h3>No paper work</h3>
                      <p>
                        Reduce administrative labor & manual errors
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="single-feature-extended">
                    <div className="icon">
                      <i className="lni lni-rocket" />
                    </div>
                    <div className="content">
                      <h3>End to end solution</h3>
                      <p>Ensure customer satisfaction by delivering quick
                        service & timely addressing their needs
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======== feature-section end ======== */}
        {/* ======== subscribe-section start ======== */}
        {/* <section id="contact" className="subscribe-section pt-120">
          <div className="container">
            <div className="subscribe-wrapper img-bg">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-7">
                  <div className="section-title mb-15">
                    <h2 className="text-white mb-25">Subscribe Our Newsletter</h2>
                    <p className="text-white pr-5">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                      diam nonumy eirmod tempor
                    </p>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-5">
                  <form action="#" className="subscribe-form">
                    <input type="email" name="subs-email" id="subs-email" placeholder="Your Email" />
                    <button type="submit" className="main-btn btn-hover">
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* ======== subscribe-section end ======== */}
        {/* Modal */}
        <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                ...
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </div>

        {/* ======== footer start ======== */}
        <footer className="footer">
          <div className="container">
            <div className="widget-wrapper">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="footer-widget">
                    <div className="logo mb-30">
                      <a href="index.html">
                        <img width="150px" src="assets/img/logo/SOZOLOGO.png" alt />
                      </a>
                    </div>
                    {/* <ul className="socials">
                      <li>
                        <a href="jvascript:void(0)">
                          <i className="lni lni-facebook-filled" />
                        </a>
                      </li>
                      <li>
                        <a href="jvascript:void(0)">
                          <i className="lni lni-twitter-filled" />
                        </a>
                      </li>
                      <li>
                        <a href="jvascript:void(0)">
                          <i className="lni lni-instagram-filled" />
                        </a>
                      </li>
                      <li>
                        <a href="jvascript:void(0)">
                          <i className="lni lni-linkedin-original" />
                        </a>
                      </li>
                    </ul> */}
                  </div>
                </div>
                {/* <div className="col-xl-2 col-lg-2 col-md-6">
                  <div className="footer-widget">
                    <h3>About Us</h3>
                    <ul className="links">
                      <li><a href="javascript:void(0)">Home</a></li>
                      <li><a href="javascript:void(0)">Feature</a></li>
                      <li><a href="javascript:void(0)">About</a></li>
                      <li><a href="javascript:void(0)">Testimonials</a></li>
                    </ul>
                  </div>
                </div> */}
                {/* <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="footer-widget">
                    <h3>Solutions</h3>
                    <ul className="links">
                      <li><a href="javascript:void(0)">How it works</a></li>
                      <li><a href="javascript:void(0)">Privacy policy</a></li>
                      <li><a href="javascript:void(0)">Terms of service</a></li>
                      <li><a href="javascript:void(0)">Refund policy</a></li>
                    </ul>
                  </div>
                </div> */}
                {/* <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="footer-widget">
                    <h3>Other Products</h3>
                    <ul className="links">
                      <li><a href="jvascript:void(0)">Accounting Software</a></li>
                      <li><a href="jvascript:void(0)">Billing Software</a></li>
                      <li><a href="jvascript:void(0)">Booking System</a></li>
                      <li><a href="jvascript:void(0)">Tracking System</a></li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </footer>
        {/* ======== footer end ======== */}
        {/* ======== scroll-top ======== */}
        <a href="#" className="scroll-top btn-hover">
          <i className="lni lni-chevron-up" />
        </a>
      </div>

    </div>
  );
}

export default App;
